export default function Footer(){
    return (
        <>
        <div  className="bg-dark text-center mt-5 pt-3 pb-4">
        <h3 style={{color:"aquamarine"}} className="mt-5 ">Thank You for Visiting !</h3>
        <ul className='ft mt-5 pb-4'>
            <li className='ft-item'> <a href='#home'>Home</a> </li>
            <li className='ft-item '><a href='#about'>About me</a> </li>
            <li className='ft-item'><a href='#project'>Skills</a> </li>
            <li className='ft-it'><a href='#contact'>Projects</a> </li>
          </ul> 
          <h5 className="text-white pb-4">Portfolio || Created By : Selvam S</h5>
        </div>
        </>
    );
}